/*global gtag*/
import React, { Component } from "react";
import { Link } from "react-router-dom";
import './HomePage.css';


class HomePage extends Component {
    constructor() {
        super();
        this.state = { size: '', sort: '', cartItems: [], products: [], filteredProducts: [] , infoProductActive: false, productSelected: {}, productSelectedTypeIndex: 0, ref: ''};
    }

    chamaTag() {

        function gtag_report_conversion(url) {
            var callback = function () {
              if (typeof(url) != 'undefined') {
                window.location = url;
              }
            };
            gtag('event', 'conversion', {
                'send_to': 'AW-935258011/CwfkCObm3esBEJvP-70D',
                'event_callback': callback
            });
            return false;
          }

        gtag_report_conversion();

    }

    componentWillMount() {

        // var details = {
        //     "email": "gustavo.sales.gman@gmail.com",
        //     "token": "8b8ff9de-c849-4db6-8f4c-7b08173d33db026b6d8f4a5c8f35bd5dec9069a47cf8351c-2358-420e-82a7-ad98f8c3a344",
        //     "currency": "BRL",
        //     "itemId1": "0001",
        //     "itemDescription1": "Notebook Prata",
        //     "itemAmount1": 100.00,
        //     "itemQuantity1": 1,
        //     "itemId2": "0002",
        //     "itemDescription2": "Nelson",
        //     "itemAmount2": 50.00,
        //     "itemQuantity2": 3,
        //     "shippingType": 1,
        //     "redirectURL": "http://sitedocliente.com",
        //     "shippingCost": 0.00,
        // };

        // var formBody = [];
        // for (var property in details) {
        //     var encodedKey = encodeURIComponent(property);
        //     var encodedValue = encodeURIComponent(details[property]);
        //     formBody.push(encodedKey + "=" + encodedValue);
        // }
        // formBody = formBody.join("&");


        // console.log('Fetch', formBody);

        // fetch('https://ws.pagseguro.uol.com.br/v2/checkout', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        //     },
        //     body: formBody
        // }).then((data) => {
        //     console.log('dado', data);
        // }).catch((error) => {
        //     console.log('erro', error);
        // });;

        if (localStorage.getItem('cartItems')) {
            this.setState({ cartItems: JSON.parse(localStorage.getItem('cartItems')) });
        }

        let ref = this.getQueryVariable('ref');
        let refCookie = localStorage.getItem('ref');
        if(ref) {
            this.setState({
                ref
            });
            localStorage.setItem('ref', JSON.stringify({ref}));
            console.log('Setado referência de: ' + ref);
        } else if(refCookie) {
            this.setState(JSON.parse(localStorage.getItem('ref')));
            console.log('Peguei do cache a referencia: ' + refCookie);
        }

        fetch('db.json').then(res => res.json()).then(data => data.products)
            .then(data => {
                this.setState({ products: data });
            });
    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        return false;
    }

    render() {
        let msg = '';
        let ref = this.state.ref;
        if(ref) {
            msg = ' ---- Indicado por ' + ref;
        }
        return (
            <div className="home">
                <div className="titulo">
                    <h1>Torta da Mamis </h1>
                    <h5>As tortas mais saborosas do planeta</h5>
                    <img className="chapeu" src="./torta.png" />
                </div>

                <div >
                    <p style={{fontWeight:'bold'}}>
                        Veganas - Vegetarianas - Sem Lactose - Padrão
                    </p>
                </div>

                <Link className="link" to="list-products">
                    <div className="opcoes-orcamento">
                        <h3>Monte seu pedido online Personalizado em 3 minutos <span className="link">aqui</span></h3>
                    </div>
                </Link>

                <h3 className="ou-entao">
                    Ou então
                </h3>

                <a onClick={this.chamaTag} href={"https://api.whatsapp.com/send?phone=5511985595639&text=Ola, gostaria de solicitar um pedido de tortas" + msg + "&source=&data="} target="_blank">
                    <div className="opcoes-orcamento">
                        <h3>Solicite pedido via Whatsapp <img src="./whatsapp.png" /> </h3>
                    </div>
                </a>
            </div>
        )
    }
}

export default HomePage;