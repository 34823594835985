import React, { Component } from 'react';
import GoogleMaps from './GoogleMaps';
import Autocomplete from 'react-google-autocomplete';
import './Shipping.css';
import { Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import util from '../util'


// const { compose, withProps, lifecycle } = require("recompose");
// const {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   DirectionsRenderer,
// } = require("react-google-maps");





class Shipping extends Component {
    constructor(props) {
        super(props);

        this.state = {
            endereco: '',
            origin: 'Rua doutor augusto de miranda 57',
            destination: 'Avenida Paulista 1100',
            preco: 0,
            pegarEncomenda: 0,
            cartItems: []
        };

        this.myRef = React.createRef();

        this.mudarEndereco = this.mudarEndereco.bind(this);
        this.calculaDistancia = this.calculaDistancia.bind(this);
        this.pegarEncomenda = this.pegarEncomenda.bind(this);
    }

    componentWillMount() {
        if (localStorage.getItem('cartItems')) {
            this.setState({ cartItems: JSON.parse(localStorage.getItem('cartItems')) });
          }

          localStorage.setItem('pegarencomenda', '0');
      
          fetch('db.json').then(res => res.json()).then(data => data.products)
            .then(data => {
              this.setState({ products: data });
            });
    }

    componentDidMount() {
        var options = {
            componentRestrictions: {
                country: "br"
            }
        };


        new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(this.myRef.current),
            {options});  


            setInterval(() => {
                this.calculaDistancia();
            }, 1000);
    }

    mudarEndereco(event) {
        this.setState({
            endereco: event.target.value,
        });
    }

    calculaDistancia() {
        console.log('Calcule1');
        if(this.state.endereco.length > 3) {
        console.log('Calcule2');
        console.log('Calculando');
        const DistanceMatrixService = new window.google.maps.DistanceMatrixService(); 
    
          DistanceMatrixService.getDistanceMatrix({
            origins: ['Rua doutor augusto de miranda, 57'],
            destinations: [this.state.endereco],
            travelMode: 'DRIVING',
            }, (response, status) => {
                if(status === 'OK') {
                    if(response  && response.rows[0] &&  response.rows[0].elements[0].distance && response.rows[0].elements[0].distance.value) {
                        const distancia = response.rows[0].elements[0].distance.value / 1000;
                        const preco = (distancia * 3 < 15 ? 15 : (distancia * 3).toFixed(2));
                        localStorage.setItem('preco', preco);
                        localStorage.setItem('endereco', this.state.endereco);
                        this.setState({
                            distancia: response.rows[0].elements[0].distance.value / 1000,
                            preco,
                        });
                    }
                }
            });
        }
    }

    pegarEncomenda() {
        localStorage.setItem('pegarencomenda', '1');
        this.setState( {
            pegarEncomenda: 1,
        });
    }
    

    render() {

        const { cartItems } = this.state;

        if (this.state.pegarEncomenda === 1) {
            return <Redirect to='/checkout' />
          }


        return (
            <div id="shipping">
                <h3>Calcular preço da entrega</h3>

                <div class="shipping-form">
                    <form method="GET" action="/">
                        <label for="endereco-entrega">Endereco de entrega:</label>
                       

                        <input placeholder="DIGITE O CEP" ref={this.myRef} value={this.state.endereco} onChange={this.mudarEndereco} type="text" className="input-entrega" name="input-entrega" />

                        {/* <Autocomplete className="input-encomenda"
                        value={this.state.endereco} onChange={this.mudarEndereco}
                            onPlaceSelected={this.mudarEndereco}
                            types={['address']}
                            componentRestrictions={{country: "br"}}
                        /> */}

                        <div class="preco-encomenda">
                            {
                                this.state.distancia ?
                                <div className="preco-final">
                                    Frete: R${this.state.preco}
                                </div>
                                        
                                : ''
                            }
                            <p className="preco-ceia">Torta: {util.formatCurrency(cartItems.reduce((a, c) => (a + c.price * c.count), 0))}</p>
                        </div>
                        
                        {
                                this.state.distancia ?
                                <p>
                                    <b>Não gostou do frete? Chama no Whats que a gente resolve isso (11)985-595-639</b><br/>
                                    Caso queira buscar a encomenda, na Rua Doutor Augusto de Miranda 57 - Pompéia - <span className="buscar-encomenda" onClick={this.pegarEncomenda}>(Clique aqui)</span>
                                </p>
                                : ''
                        }

                        <Link className="botao-finalizar finalizar" to="/checkout">Finalizar Compra</Link>
                    </form>

                    
                </div>
            </div>
        ) 

        ;
    }
}

export default Shipping;