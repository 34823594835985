import React, { Component } from "react";
import { Link } from "react-router-dom";
import './Checkout.css';
import Basket from './Basket';

class Checkout extends Component {
    constructor() {
        super();
        this.state = { size: '', sort: '', cartItems: [], products: [], filteredProducts: [], preco: 15, endereco: '', pegarEncomenda: '0', ref: '' };
        this.generatePagseguroLink = this.generatePagseguroLink.bind(this);
        this.chamarNoWhatsapp = this.chamarNoWhatsapp.bind(this);
    }

     openInNewTab(url) {
        var win = window.open(url, '_blank');
        win.focus();
    }

    componentWillMount() {

        if (localStorage.getItem('cartItems')) {
          this.setState({ cartItems: JSON.parse(localStorage.getItem('cartItems')),
          preco: parseFloat(localStorage.getItem('preco')),
          pegarEncomenda: localStorage.getItem('pegarencomenda'),
          endereco: localStorage.getItem('endereco') });
        }

        if (localStorage.getItem('ref')) {
          let ref = JSON.parse(localStorage.getItem('ref'));
          console.log('ref = ', ref);
          this.setState(ref);
        }
    
        fetch('db.json').then(res => res.json()).then(data => data.products)
          .then(data => {
            this.setState({ products: data });
          });
      }

      generatePagseguroLink() {
        //   console.log('Dadddoos', this.state.cartItems);
          console.log('Dadddoos', JSON.stringify(this.state.cartItems));

        //   fetch('http://localhost:9890/generate-payment', 
          fetch('https://back-end-buffet-ceia.herokuapp.com/generate-payment', 
          {
              method: 'POST',
              headers: {'Content-Type':'application/json'},
              body: JSON.stringify({data: this.state.cartItems, preco: parseFloat(this.state.preco).toFixed(2), endereco: this.state.endereco, frete: this.state.pegarEncomenda}),
          }).then(res => res.json())
          .then(data => {
            this.openInNewTab(data.link);
          })
          .catch((error) => {
              console.log(error);
              alert('Erro ao abrir o pagseguro por favor opte por outra forma de pagamento');
          });
      }

      chamarNoWhatsapp() {
          let txt = 
          `
          Ola, acessei o Torta da Mamis
        e gostaria de solicitar o seguinte orçamento: 
          `;

          this.state.cartItems.forEach((item) => {
            txt += ' - ' + item.count + ' ' + item.title + ': R$ ' + item.price + ' -------';
          });

          if(this.state.ref) {
            txt += ' ------ Indicado por: ' + this.state.ref;
          }

          txt += ' ------ Endereço: ' + this.state.endereco;

          if(this.state.pegarEncomenda == '1') {
            txt += '--- Irei buscar a deliciosa torta no endereco de vocês.'
        } else {
            txt += ' ========== Frete: ' + this.state.preco

        }
          this.openInNewTab("https://api.whatsapp.com/send?phone=5511985595639&text=" + txt + "&source=&data='");
      }

      handleRemoveFromCart = (e, product) => {
        this.setState(state => {
          const cartItems = state.cartItems.filter(a => a.id !== product.id);
          localStorage.setItem('cartItems', JSON.stringify(cartItems));
          return { cartItems: cartItems };
        })
      }

    render() {
        return (
            <div class="checkout">
                <h1>Salles Buffet - Orçamento de páscoa personalizado</h1>

                <div className="row">
                    <div className="col-md-12">
                        <Basket pegarEncomenda={this.state.pegarEncomenda} buttonFinalizar={false} cartItems={this.state.cartItems} handleRemoveFromCart={this.handleRemoveFromCart} preco={this.state.preco} />
                    </div>
                </div>

                <ul>

                </ul>
                <div class="voltar">


                <a className="pagar-pagseguro" href="#" onClick={this.chamarNoWhatsapp}>
                    Concluir via Whatsapp <img src="./whatsapp.png" />
                </a>
                
                <p className="ou-entao">
                    Ou então
                </p>

                <a className="pagar-pagseguro" href="#" onClick={this.generatePagseguroLink}>
                    Pagar com PagSeguro
                </a>

                

                <p className="numero-contato">
                    Caso queira finalizar a compra por ligação, basta ligar para (11)954-308-048
                </p>


                <Link className="continuar-comprando" to="list-products">
                    Continuar comprando
                </Link>

                    
                </div>

            </div>
        )
    }
}


export default Checkout;